import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {Observable, tap} from 'rxjs';
import { User } from '../types/user.model';
import { environment } from '../../../environments/environment';
import {SessionService} from "../../state/session.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private session = inject(SessionService);
  private snack = inject(MatSnackBar);

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.API_URL + '/api/user/all');
  }

  updateLanguage(language: string) {
    return this.http.patch<User>(environment.API_URL + '/api/user/language', { language }).subscribe();
  }

  update(user: User) {
    this.http.patch<User>(environment.API_URL + '/api/user/admin/' + user.uuid, user).pipe(
      tap(() => this.snack.open('User updated', 'OK', { duration: 5000 })))
      .subscribe();
  }

  fetchUser() {
    return this.http.get<User>(environment.API_URL + '/api/user')
      .pipe(tap((user) => this.session.user = user));
  }
}
